class PlacesMap {
  constructor($wrap) {
    this.query = undefined;
    this.$wrap = $wrap;
    this.$places = $wrap.find(".js-map-place");
    this.$toggles = $wrap.find(".js-map-place-pin");

    this.init();
  }

  init() {
    const query = window.matchMedia(
      "(hover: hover) and (pointer: fine), (min-width: 1280px)"
    );
    query.addEventListener("change", this.matchQuery);
    this.$wrap.on('mapClose', () => {
      this.closeAllPlaces()
    })
    this.$wrap.on('mapOpen', () => {
      if (this.$wrap[0] && this.$wrap[0].id === 'PlaceMapMobile') {
        setTimeout(() => {
          const box = this.$wrap.find('.LocationMap__imgWrap');
          const img = this.$wrap.find('.LocationMap__imgDragger');
          box && box[0].scrollTo({ left: (img.width() / 1280 * 614) - box.width() / 2, top: img.height() })
        }, 50)
      }
    })
    this.matchQuery(query);
  }

  matchQuery({ matches }) {
    this.query = matches;
    if (matches) {
      this.$toggles.on("mouseenter mouseleave", this.togglePlace.bind(this));
    } else {
      this.$toggles.on("click", this.togglePlace.bind(this));
      $(document).on("click", (event) => {
        if (
          !event.target.closest(".js-map-place") ||
          event.target.closest(".js-map-place-close")
        ) {
          this.closeAllPlaces();
        }
      });
    }
  }

  togglePlace(event) {
    const $place = $(event.target).closest(".js-map-place");
    if ($place.hasClass("_active")) {
      this.closeAllPlaces();
    } else {
      this.closeAllPlaces();
      this.activatePlace($place);
    }
  }

  activatePlace($place) {
    if (!this.query) $('body').addClass('_noScroll')
    const $popup = $place.find(".js-map-place-popup");
    $place.removeAttr("data-horizontal-position");
    $place.removeAttr("data-vertical-position");
    const popupLeft = $popup.offset().left;
    const popupRight = popupLeft + $popup.outerWidth();
    const popupTop = $popup.offset().top;
    const parentTop = this.$wrap.offset().top;

    if (popupLeft < 0) {
      $place.attr("data-horizontal-position", "moved-right");
    } else if (popupRight > window.innerWidth) {
      $place.attr("data-horizontal-position", "moved-left");
    }

    if (popupTop < parentTop) {
      $place.attr("data-vertical-position", "moved-down");
    }
    // NOTE: таймаут нужен для Firefox -- при нажатии на точку
    // выползает адресная строка и из-за этого не успевает
    // просчитаться высота экрана
    setTimeout(() => {
      $popup.css("--tooltip-vh", `${window.innerHeight * 0.01}px`);
      $place.addClass("_active");
      this.$wrap.addClass("_place-opened");
    }, !this.query ? 100 : 0)
  }

  closeAllPlaces() {
    this.$places.removeClass("_active");
    this.$wrap.removeClass("_place-opened");
    if (!this.query) $('body').removeClass('_noScroll')
  }
}
