$.validator.addMethod(
  'regex',
  function (value, element, regexp) {
    var re = new RegExp(regexp);
    return this.optional(element) || re.test(value);
  },
  'Поле заполнено не верно'
);

class InteractiveForm {
  constructor(el, opts = {}) {
    this.$form = $(el);
    this.isCallTouch = this.$form.data('calltouch');
    this.$submitBtn = this.$form.find('button[type=submit]');

    let $form = this.$form;
    if ($form.data('action')) {
      $form.prop('action', $form.data('action'));
    }

    let $phoneInputs = $form.find('[name="tel"], [type="tel"]');

    $phoneInputs.inputmask({
      mask: '+7 999 999 99 99',
      showMaskOnHover: false,
    });

    $(document).on('click', (e) => {
      if ($(e.target).parent('.InputText').length == 0) {
        $form.find('.InputText_inpt').each((i, e) => {
          if ($(e).val() !== '') {
            $(e).addClass('_focus');
          }
        });
      }
    });

    $form.find('[name="captcha"]').inputmask({
      mask: '99999',
      showMaskOnHover: false,
    });

    let validatorOpts = {
      rules: {},
      submitHandler: opts.submitHandler
        ? opts.submitHandler.bind(this)
        : this.standartFormHandler.bind(this),
      errorElement: 'div',
      errorClass: 'Form__error',
      focusInvalid: false,
      highlight: function (element) {
        const $field = $(element.closest('.Form__group')).add(
          $(element.closest('.Checkbox'))
        );
        $field.addClass('_error').removeClass('_valid');
      },
      unhighlight: function (element) {
        const $field = $(element.closest('.Form__group')).add(
          $(element.closest('.Checkbox'))
        );
        $field.removeClass('_error').addClass('_valid');
      },
      errorPlacement: ($errorLabel, $el) => {
        if ($el.attr('type') === 'checkbox') {
          return false;
        } else {
          $el.after($errorLabel);
        }
      },
    };

    $phoneInputs.each((index, el) => {
      validatorOpts.rules[el.name] = {
        required: !!el.required,
        regex: /\+7\s\d\d\d\s\d\d\d\s\d\d\s\d\d/,
      };
    });

    if (opts.validatorParams) {
      $.extend(true, validatorOpts, opts.validatorParams);
    }

    if (opts.successBlockMod) {
      $.extend(true, opts, { successBlockMod: 'default' });
    }

    this.opts = opts;
    this.validator = $form.validate(validatorOpts);

    $form
      .find('.Form__group input')
      .on('focus', (e) => {
        $(e.currentTarget).addClass('_focus');
      })
      .on('focusout', (e) => {
        if ($(e.currentTarget).val() == '') {
          $(e.currentTarget).removeClass('_focus');
        } else {
          $(e.currentTarget).addClass('_focus');
        }
      });
  }

  standartFormHandler(form) {
    let $form = $(form);

    let phone_val = $form.find('input[name=tel]').val();
    phone_val = phone_val.replace(/[\s+-]/g, '');

    let dataToSend = $form.serializeObject();

    this.$submitBtn.prop('disabled', true).addClass('_loading');

    const it = this;

    $.ajax({
      url: form.action,
      type: form.method,
      data: dataToSend,
      success: (_response, status) => {
        $form.find('.Form__group input').removeClass('_focus');
        if (status === 'success') {
          if (it.opts.onSuccess) {
            it.opts.onSuccess();
          } else {
            $.fancybox.close();
          }
          form.reset();
        } else {
          alert(
            'Не удалось отправить форму! Попробуйте перезагрузить страницу'
          );
        }
      },
      error: () => {
        alert('Не удалось отправить форму! Попробуйте перезагрузить страницу');
      },
      complete: () => {
        it.$submitBtn.prop('disabled', false).removeClass('_loading');
      },
    });
  }

  destroy() {
    this.validator.destroy();
    this.$form.find('input').inputmask('remove');
  }
}
