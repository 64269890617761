(function () {
  const $form = $('#CallMeForm');

  new InteractiveForm($form[0], {
    validatorParams: {
      rules: {
        name: {
          required: true,
        },
        tel: {
          required: true,
        },
        agree: {
          required: true,
        },
      },
    },
    onSuccess: () => {
      $form.trigger('fancybox-request', {
        selector: '.SuccessPopup',
      });
    },
  });
})();
