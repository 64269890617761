(function() {
  let simplebar;

  function openFancyBox(selector) {
    $.fancybox.open({
      src: selector,
      type: 'inline',
      smallBtn: false,
      buttons: [],
      closeExisting: false,
      touch: false,
      wheel: false,
      // SimpleBar must be initialized after fancybox loads or it will break
      beforeShow: () => {
        $(selector).trigger('mapOpen');
      },
      afterShow: () => {
        const $el = $(selector);
        const $scroller = $el.find('.js-simple-bar');
        if ($scroller.length) {
          simplebar = new SimpleBar($scroller[0], {
            autoHide: true,
          });
        }
      },
      afterClose: () => {
        simplebar && simplebar.unMount();
        $(selector).trigger('mapClose');
      },
    });
  }

  $(document).on('fancybox-request', (_, params) => {
    const selector = params.selector;
    selector && openFancyBox(selector);
  });

  $(function() {
    $('[data-fancybox-open]').click(function(event) {
      event.preventDefault();
      const selector = $(this).data('fancybox-open');
      openFancyBox(selector);
    });
  });

  $(function() {
    $('.js-fancybox-close').click(function(event) {
      event.preventDefault();
      $.fancybox.close();
    });

    $('.js-fancybox-close-all').click(function(event) {
      event.preventDefault();
      $.fancybox.close(true);
    });
  });
})();
