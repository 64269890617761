(function() {
  const $header = $('header');
  $(document).on('click', '.js-scroll-link', function(event) {
    event.preventDefault();

    const selector = this.dataset.scrollTarget;
    const $target = $(selector);

    if ($target.length) {
      $.fancybox.close(true);
      $('html, body').animate({
        scrollTop: $target.offset().top - (window.matchMedia('(max-width: 1279px)').matches ? $header.height() / 2 : 0),
      });
    }
  });
})();
